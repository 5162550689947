<template>
    <EntitySingle
      :config="config"
      :entity.sync="entity"
      :loaded.sync="entityLoaded"
      :extra-validation="extraValidation"
      :submit-callback="submitCallback"
      :disable-save="disableSave"
    >
      <b-card v-if="entityLoaded"> <!-- change it to v-if="entityLoaded" during api integration -->
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
            </template>
            <EntityEditSection
              :module="config.module"
              :entity.sync="entity"
              :form-fields="formFields"
              allow-autofocus
            />                    
          </b-tab>
        </b-tabs>
      </b-card>
    </EntitySingle>
  </template>
  
  <script>
  import { BTab, BTabs, BCard, BFormCheckbox } from 'bootstrap-vue'
  import { translationUtils } from '@core/mixins/ui/translations'
  import { formUtils } from '@core/mixins/ui/forms'
  import {
    getUserRights,
    isAdmin,
    isSeller,
    isSupplier,
    canEditPrice,
    canPublishToShop,
    canUnPublishFromShop,
  } from '@/auth/utils'
  import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
  import EntitySingle from '@/layouts/entity/EntitySingle.vue'  
  import storeModule from '../normalSubscriptionPaymentsStoreModule'
  
  export default {
    components: {
      EntityEditSection,
      EntitySingle,
      BTab,
      BTabs,
      BCard,
      BFormCheckbox,
    },
    mixins: [translationUtils, formUtils],
    data() {
      return {
        entity: {
          rights: [],
          userGroup: {},
          purchasedUser: {},
          company: {},
          active: true,
          status: '',
        //   title: '',
        // subtitle: '',
        // transactionId: '',
        // enabled: false,
        // subscription: false,
        // monthlySubscription: '',
        // yearlySubscription: '',
        // maxProducts: 0,
        // description: '',

        },
        subscriptionPackage: {
        id: 0,
        title: '',
      },
        entityLoaded: false,
        rightsLoaded: false,
        grantAllRights: false,
        grantGroupRights: [],
        roleOptions: [],
        disableSave: true,
      }
    },
    computed: {
      config() {
        return {
          store: storeModule,
          module: 'subscriptions',
          endpoint: 'subscriptions',
          route: 'normal-subscription-payments',
          title: {
            single: this.$t('Normal Subscription Payment'),
            plural: this.$t('Normal Subscriptions Payment'),
          },
        }
      },
      
      activeSubscriptionText() {
        return this.entity.active ? this.$t('Yes') : this.$t('No');
      },
      formFields() {
        return [
        {
            id: 'title',
            name: 'userGroupTitle',
            parent: 'userGroup',
            object: true,
            type: 'text',
            label: this.$t('Subscription Name'),
            readonly: true,
          },
          {
            id: 'type',
            type: 'text',
            label: this.$t('Subscription Type'),
            readonly: true,
          },
          {
            id: 'name',
            parent: 'purchasedUser',
            type: 'text',
            label: this.$t('Subscriber Name'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          {
            id: 'title',
            name: 'companyTitle',
            parent: 'company',
            type: 'text',
            label: this.$t('Company Name'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          // {
          //   id: 'active',
          //   name: 'subscriptionActive',
          //   type: 'text',
          //   label: this.$t('Subscriber\'s Active Subscription'),
          //   rules: 'min:3',
          //   colSize: 4,
          //   readonly: true,
          //   value: this.activeSubscriptionText,
          //   placeholderVal: "yes",
          //   placeholder: this.activeSubscriptionText,
          // },
          {
            id: 'paymentId',
            type: 'text',
            label: this.$t('Transaction ID'),
            rules: 'min:3',
            colSize: 4,
            readonly: true,
          },
          {
            id: 'amount',
            type: 'text',
            label: this.$t('Paid Amount'),
            rules: 'positive',
            readonly: true,
          },
          {
            id: 'startDate',
            type: 'datetime',
            label: this.$t('Subscription Start Date'),
            // maxDate: new Date(),
            readonly: true,
            disable: true,
          },
          {
            id: 'endDate',
            type: 'datetime',
            label: this.$t('Subscription End Date'),
            // maxDate: new Date(),
            readonly: true,

          },
        ]
      },
      extraActions() {
        const canPublish = canPublishToShop()
        const canUnPublish = canUnPublishFromShop(this.entity.supplier)
        return [
          {
            icon: 'EyeIcon',
            title: 'View in e-shop',
            href: `${process.env.VUE_APP_SHOP_URL}product/${this.entity.eshopSlug}`,
            variant: 'outline-secondary',
            renderCondition: {
              key: 'published',
              value: true,
            },
          },
          {
            endpoint: 'eshop/publish',
            icon: 'UploadCloudIcon',
            title: 'Publish to e-shop',
            renderCondition: {
              key: 'published',
              value: false,
            },
            authorityValidation: canPublish,
          },
          {
            // callback: 'updateEshop',
            endpoint: 'eshop/publish',
            icon: 'UploadCloudIcon',
            title: 'Update e-shop',
            renderCondition: {
              key: 'published',
              value: true,
            },
            authorityValidation: canPublish,
          },
          // {
          //   callback: 'updateStockPrice',
          //   endpoint: 'eshop/update-stock-price',
          //   icon: 'UploadCloudIcon',
          //   title: 'Update e-shop Stock and Price',
          //   variant: 'warning',
          //   renderCondition: {
          //     key: 'published',
          //     value: true,
          //   },
          //   authorityValidation: canPublish,
          // },
          {
            endpoint: 'eshop/change-product-status',
            icon: 'DownloadCloudIcon',
            title: 'Remove from e-shop',
            variant: 'danger',
            renderCondition: {
              key: 'published',
              value: true,
            },
            authorityValidation: canUnPublish,
          },
        ]
      },
    },
    mounted() {
      this.$store.dispatch('app-common/fetchPaymentMethods').then(response => {
        this.paymentMethodOptions = response.data
      })
      this.$store.dispatch('app-common/fetchCompanies').then(response => {
        this.companyOptions = response.data
      })
      this.$store.dispatch('app-common/fetchPlainSubscriptions').then(response => {
        this.subscriptionOptions = response.data
      })
      this.$watch('entityLoaded', this.loadSave);
      // this.loadSubscriptionPackageTitle();
    },

    methods: {
      extraValidation() {
        return ''
      },
      submitCallback() {
        this.$router.push({ name: 'normal-subscription-payments-list' })
      },
      loadSubscriptionPackageTitle() {
      this.subscriptionPackageTitle = this.entity.company ? this.entity.company.title : '';
      },
      loadSave() {
        if(this.entity.status === 'ACTIVE') {
          this.disableSave = false;
        } else {
          this.disableSave = true;
        } 
      },
    },

  }

  </script>
  
  